.it-header-slim-wrapper {
  background-color: darken($secondary, 5);
}

.it-header-center-wrapper {
  font-weight: 400;

  .it-header-center-content-wrapper {
    .it-brand-wrapper {
      .it-brand-text {
        display: none;
      }

      a .icon {
        width: auto;
        margin: 0;

        img {
          max-height: 100%;
        }
      }
    }
  }
}
