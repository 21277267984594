//megamenu mobile definition
.navbar {
  .navbar-collapsable {
    .menu-wrapper {
      .it-brand-wrapper {
        padding-top: 1.5rem;
        background-color: $primary;

        .icon {
          display: flex;
          height: 60px;
          align-items: center;

          img {
            max-height: 100%;
          }
        }

        h2 {
          max-width: 11em;
          color: $secondary-text;
          font-size: 1.3em;
          font-weight: 400;

          text-transform: uppercase;
        }
      }
    }
  }
}
